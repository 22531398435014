<template>
  <div>
    <div class='text-2xl lg:text-3xl font-semibold uppercase w-full lg:w-1/4'>
      Watched Videos
    </div>
    <div class='flex flex-col lg:flex-row gap-y-8 lg:gap-x-8 mt-4'>
      <div class='w-full lg:w-1/4 flex flex-col-reverse lg:flex-col gap-y-4'>
        <div class='w-full lg:w-40 h-28 lg:h-40 text-white flex flex-col justify-center items-start p-8'
          :style='`background-color: ${eventMainThemeColor};`'>
          <div class='text-lg uppercase font-medium'>Watched</div>
          <div class='text-5xl font-normal'>{{myPageDataWatchedVideosCount}}</div>
        </div>
      </div>
      <div class='w-full lg:w-3/4'>
        <table  v-if='!noWatchVideoData'
          class='table-fixed w-full text-sm lg:text-base'>
          <thead>
            <tr class='uppercase text-gray-600'>
              <th class='py-2 lg:py-4 px-2 text-left w-8 whitespace-nowrap align-top'></th>
              <th class='py-2 lg:py-4 px-2 text-left w-64 whitespace-nowrap align-top'>날짜</th>
              <th class='py-2 lg:py-4 px-2 text-right whitespace-nowrap align-top'>누적 이수 시간</th>
            </tr>
          </thead>
          <my-page-watched-videos 
            v-for='(dayData, index) in myDataByDay'
            :key='`data-for-day-${index}`'
            :data-watched-videos-for-day='dayData' />
        </table>
        <div v-else
          class='my-2 py-16 bg-gray-100 rounded-md text-gray-600 text-center'>
          No videos have been watched yet.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MyPageWatchedVideos from '@/components/my-page/MyPageWatchedVideos.vue'
import isEmpty             from 'lodash/isEmpty'

export default {
  name: 'MyPageWatchedTime',
  components: {
    MyPageWatchedVideos,
  },
  computed: {
    ...mapGetters('myPages', [
      'myDataByDay',
      'myPageDataWatchedVideosCount'
    ]),
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
    noWatchVideoData () {
      return isEmpty(this.myDataByDay)
    },
  },
}
</script>
