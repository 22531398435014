class ScoreCalculationHelpers {

  static calcedScore (eventId, date, totalWatchedMinuteTodayForCredit) {
    if (eventId === 7) {
      if (date !== '2022.04.30') {
        switch (true) {
          case (totalWatchedMinuteTodayForCredit < 60):
              return 0
          case (totalWatchedMinuteTodayForCredit < 120):
              return 1
          case (totalWatchedMinuteTodayForCredit < 180):
              return 2
          case (totalWatchedMinuteTodayForCredit < 240):
              return 3
          case (totalWatchedMinuteTodayForCredit < 300):
              return 4
          case (totalWatchedMinuteTodayForCredit < 360):
              return 5
          default:
              return 6
        }
      } 
    } else if (eventId === 29 || eventId === 40) {
      switch (true) {
        case (totalWatchedMinuteTodayForCredit < 60):
          return 0
        case (totalWatchedMinuteTodayForCredit < 120):
          return 1
        case (totalWatchedMinuteTodayForCredit < 180):
          return 2
        case (totalWatchedMinuteTodayForCredit < 240):
          return 3
        case (totalWatchedMinuteTodayForCredit < 300):
          return 4
        case (totalWatchedMinuteTodayForCredit < 360):
          return 5
        case (totalWatchedMinuteTodayForCredit >= 360):
          return 6
        default:
          return 0
      }
    } else if (eventId === 31) {
      if (date == '2023.04.29') {
        switch (true) {
          case (totalWatchedMinuteTodayForCredit < 60):
            return 0
          case (totalWatchedMinuteTodayForCredit < 120):
            return 1
          case (totalWatchedMinuteTodayForCredit < 180):
            return 2
          case (totalWatchedMinuteTodayForCredit >= 180):
            return 3
          default:
            return 0
        }
      } else {
        switch (true) {
          case (totalWatchedMinuteTodayForCredit < 60):
            return 0
          case (totalWatchedMinuteTodayForCredit < 120):
            return 1
          case (totalWatchedMinuteTodayForCredit < 180):
            return 2
          case (totalWatchedMinuteTodayForCredit < 240):
            return 3
          case (totalWatchedMinuteTodayForCredit < 300):
            return 4
          case (totalWatchedMinuteTodayForCredit < 360):
            return 5
          case (totalWatchedMinuteTodayForCredit >= 360):
            return 6
          default:
            return 0
        }
      }
    } else {
      return 0
    }
  }

}

export default ScoreCalculationHelpers
